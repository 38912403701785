const OrderModule = (() => {
    const has_new_orders = (venueId) =>{
      let venue_id = $('#venueId').val();
      if(venueId != venue_id) return
      if(!$('.open-restaurant-orders').length) return
      $('#servername-drop').trigger('change')
    }
    const pushJoinOrderNotification = (venueId, joinRequest, checkout = false, orderType, orderId) => {
    //   if (VENUE_ID == venueId){
        let allJoinRequest = addJoinOrderNotification(joinRequest);
        if(checkout && orderType === 'order-ahead') {
          $.promisedAjax({
            url: `/orders/checkouts/notification?orderId=${orderId}`,
            type: 'GET',
            contentType: 'application/json',
            dataType: 'json'
          }).then((result) =>{
            renderjoinRequestNotifications(result.data, true);
            joinRequestNotifications(result.data);
          }).catch((error)=>{
            console.error('Unable to load notification checkout', error)
          })
        } else {
          renderjoinRequestNotifications(allJoinRequest);
          joinRequestNotifications();
        }
    //   }
    };
  
    const pushJoinOrderApprovedNotification = (venueId, joinRequest) => {
      // if (VENUE_ID == venueId){
        if (joinRequest.status == 'approve'){
          Swal.fire(`You have now joined ${joinRequest.user}'s order !`, "", "success");
          if( ['order-ahead', 'waitlist'].includes($(".order-type li.active").find('a').attr('name')) ) checkForInCartOrder();
          loadCurrentCartItems();
        } else if (joinRequest.status == 'reject') {
          Swal.fire(`Your request to join ${joinRequest.user}'s order has been rejected !`, "", "error");
        }
      // }
    };
  
  
    const pushOrderAheadInvitationNotification = (venueId, info) => {
      // if (VENUE_ID == venueId){
        let hostId_orderAhead = info.hostId_orderAhead
        let userEmail         = info.userEmail
        if(hostId_orderAhead){
          //&& !(foundIncartOrder && foundIncartOrder.parent_order_id)){
          let userId = $('#userId').val();
          GetOrderInfo({order_id: hostId_orderAhead, user_id: userId, email: userEmail}).then((orderInfo)=>{
            if(orderInfo == 403){
              Swal.fire({
                title: "Current account is not avaiable",
                text: `You need to log in with account: ${userEmail} to confirm the invitation !`,
                icon: "error",
                showCancelButton: false,
                showConfirmButton:true,
                allowOutsideClick: true
              });
              return
            }else if(orderInfo == 404 || orderInfo == 402){
              Swal.fire({
                title: "Can not get order informations",
                text: `Please try again !`,
                icon: "error",
                showCancelButton: false,
                showConfirmButton:true,
                allowOutsideClick: true
              });
              return
            }
            OrderAheadInfo(orderInfo);
          })
        }
      // }
    };
  
    const pushJoinOrderUpdateType = (venueId, data) => {
      // if (VENUE_ID == venueId){
        if (data.popup){
          Swal.fire(data.popup, '', 'info');
        }
        if (data.toastr){
          toastr.info(data.toastr);
        }
        if(data.order_type == 'order-ahead'){
          loadCurrentCartItems({parentOrderUpdate: true, RequestedReservation:true});
          checkForInCartOrder();
        }else{
          loadCurrentCartItems({parentOrderUpdate: true});
        }
      // }
    };
  
    const pushRefreshIncartOrder = (venueId, data) => {
      // if (VENUE_ID == venueId){
        refreshOtherOrders();
      // }
    };
  
    var update_joinable_orders = (venue_id, data) => {
      // if(VENUE_ID != venue_id) return true
      if(!JOIN_REQUESTS.find(el => el.orderId == data.orderId)) {
        JOIN_REQUESTS.push(data)
      }
      return renderOtherOrders(JOIN_REQUESTS)
    }
  
    const pushDeliveryPhoneNumber = (data)=>{
      if(data.personType == 'delivery-staff' && data.status == 'ordered'){
        if($('#checkout-timing-'+data.orderId).length>0){
          let namePersonDelivery ='';
          let arrayStaff = data.personName.split(' ');
          if(arrayStaff.length >=2){
            namePersonDelivery = arrayStaff[0]+' '+ arrayStaff[1].slice(0,1)+'.';
          }else{
            namePersonDelivery = arrayStaff;
          }
          $('#checkout-timing-'+data.orderId).find('#changePhone').text(`${namePersonDelivery} at ${data.personPhone}`);
          $('#checkout-timing-'+data.orderId).find('#changePhone').removeAttr("style");
          Swal.fire({
            title: "Update shipping phone number",
            text: `Order number ${data.orderId} will be delivered by ${data.personName} with phone number: ${data.personPhone}`,
            icon: "success"
          });
        }
      }
    }
    const pushChangeTimeReserveOrderAhead = (data)=>{
      // if (VENUE_ID == data.venue_id){
        $('#order-ahead-date').val(moment(data.order_expected_date).format('MM/DD/YYYY h:mm A'));
        var dateReservationTime = moment(data.order_expected_date).format('MMMM DD, h:mma');
        Swal.fire({
          title: "Your reservation was updated",
          text: `The restaurant has updated your reservation to be on ${dateReservationTime}`,
          icon: "success"
        });
      // }
    }
  
    const pushConfirmDeliveredOrder = (data)=>{
      function swalConfirm() {
        Swal.fire({
          title: `Confirm received order`,
          text: 'Did you receive the delivery?',
          icon: "warning",
          showCancelButton: false,
          showConfirmButton:false,
          allowOutsideClick: true
        });
      }
      swalExtend.call(self, {
        swalFunction: swalConfirm,
        hasCancelButton: true,
        buttonNum: 2,
        buttonColor: ["#b30000", "#3CB371"],
        buttonNames: ["No","Yes"],
        clickFunctionList: [
          function() {
            return false;
          },
          function() {
            if (data.orderId) {
              return $.promisedAjax({
                url: `/orders/confirmReceivedOrder`,
                type: 'POST',
                data: JSON.stringify({
                  orderId: data.orderId,
                }),
                contentType: 'application/json',
                dataType: 'json'
              }).then((res) => {
                if(res && res.data && res.data.delivery_received_order){
                  let timeConfirm = moment(res.data.delivery_received_order).format('MM/DD/YYYY HH:mm a');
                  $(`#receipt-confirmed-order-${data.orderId}`).text(timeConfirm);
                }
                return res;
              })
              .catch((err) => {
                console.error('Unable to update order values', err);
              })
            }
          },
        ]
      });
    }
  
    const pushJoinOrderCheckoutUpdate = (venueId, data) => {
      // if (VENUE_ID == venueId){
        if (data.popup){
          Swal.fire(data.popup, '', 'info');
        }
        if (data.refreshAll){
          checkForOrdersInCheckout(null, false, {firstLoad: true, openOrderId: data.orderId});
        } else {
          checkForOrdersInCheckout(null, data.orderId);
        }
      // }
    };
    const AlertToUser = (data) => {
      if (!data.popup) return
      Swal.fire({
        title: data.popup,
        icon: data.popupType || 'success',
        showCancelButton: false,
        confirmButtonColor: '#02843d',
        confirmButtonText: 'OK'
      },function(comfirm) {
        console.log("redirecting from alerttouser....", data);
  
        if(data.reload_page) window.location.reload();
      })
    };
    
    const refreshCancelItemOrder = (venueId, data) => {
      // if (VENUE_ID == venueId){
        let cancelledItemsHTML = '';
        data.cancelledItems.forEach((item) => {
           cancelledItemsHTML += '<li>' + item.item_name + '</li>';
        });
  
        Swal.fire({
          title: "The restaurant has just cancelled your order for these item(s)",
           html: `<hr>
                <ul style="width:50%; margin:0 auto; text-align: left; list-style-type: disc; margin-bottom: 10px;">
                    ${cancelledItemsHTML}
                </ul>`,
          icon: "warning",
          allowOutsideClick: true,
        })
  
        loadCurrentCartItems();
        checkForOrdersInCheckout(null, data.orderId);
        refreshFreeFoodScreen();
      // }
    }

    //redirect to checkout
    const goToOrderCheckout = (data) => {
      const orderId = data && data.data && data.data.orderId || ""; 
      if (orderId) {
          window.location.href = `/rate/order/${orderId}/tip`;
      }
    };
  
    //Public methods
    return {
      has_new_orders,
      pushJoinOrderNotification,
      pushJoinOrderApprovedNotification,
      pushJoinOrderUpdateType,
      pushJoinOrderCheckoutUpdate,
      pushRefreshIncartOrder,
      update_joinable_orders,
      AlertToUser,
      refreshCancelItemOrder,
      pushOrderAheadInvitationNotification,
      pushDeliveryPhoneNumber,
      pushConfirmDeliveredOrder,
      pushChangeTimeReserveOrderAhead,
      goToOrderCheckout
    }
  })();

  // export default OrderModule;
